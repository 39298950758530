import * as React from "react";
import * as Bulma from 'react-bulma-components';
import { Helmet } from 'react-helmet';
import '../styles/styles.scss';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Bank of Prostir</title>
        <meta name="description" content="Bank of Prostir"></meta>
      </Helmet>

      <Bulma.Hero size="fullheight" className="main-hero">
        <Bulma.Hero.Body>
          <Bulma.Container textAlign="center">
            Bank of Prostir
          </Bulma.Container>
        </Bulma.Hero.Body>
        <Bulma.Hero.Footer>
          <Bulma.Content textAlign="right" style={{ padding: "0.5rem 1rem" }}>
            <p>
              Made with &#10084; in Canada by <a href="https://uglyunicorn.ca">Ugly Unicorn</a>
              {" "}//{" "}
              Photo by <a href="https://unsplash.com/@jdent?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Jason Dent</a>
              {" "}on{" "}
              <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
            </p>
          </Bulma.Content>
        </Bulma.Hero.Footer>
      </Bulma.Hero>
    </>
  )
}

export default IndexPage
